.float-end {
  position: absolute;
  bottom: 10%;
  left: 47.1%;
  background: red;
  border: 1px solid red;
  color: #fff;
  border-radius: 10px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .float-end {
    display: none;
  }
}
